import { useEffect, useState } from 'react';

import '../../assets/css/swap.less'
import setting from '../../assets/img/setting.png';
import history from '../../assets/img/history.png';
import redo from '../../assets/img/redo.png';
import logo from '../../assets/img/logo.png';
import updw from '../../assets/img/updw.png';
import arrowup from '../../assets/img/arrawo_up.png';
import b1 from '../../assets/img/b1.png';
import b2 from '../../assets/img/b2.png';
import b3 from '../../assets/img/b3.png';
import b4 from '../../assets/img/b4.png';
import b5 from '../../assets/img/b5.png';
import b6 from '../../assets/img/b6.png';
import { Button, Mask, Space,Popup } from 'antd-mobile';
import 'antd/dist/reset.css';
import close from '../../assets/img/close.png';


const SwapView = () => {

  const [visible, setVisible] = useState(false)
  const [visible5, setVisible5] = useState(false)
  const [visible6, setVisible6] = useState(false)
  const [selecTed,setSelected] = useState(1)
  const [selecTedA,setSelectedA] = useState(1)
  const [isA,setIsA] = useState(true)
  
  const [isSelected,setIsSelected] = useState(1)
  const [comminsoon,setComminsoon] = useState(false)
  
  const [isInParam,setIsInparam] = useState({
    a:{
        img: logo,
        name:'SOAVE'
    },
    b:{
        img:b1,
        name:'BTC'
    }
  })
  
  useEffect(() => {
  }, [])


  const onSelect = (item:any) => {
    setVisible5(false)
    let inOldParm
    console.log(isA)
    if(isA==true){
        if(isInParam.b.name == item.name){
            inOldParm = isInParam.a
            console.log('123',inOldParm)

            // 使用对象展开语法创建新的a对象，并更新需要修改的键值对
            const updatedA = {
                ...isInParam.b,
                name: inOldParm.name,
                img: inOldParm.img // 更新name属性的值
            };
        
            // 创建新的状态对象，更新a对象
            const updatedState = {
                ...isInParam,
                b: updatedA
            };
        
            // 使用更新函数来更新状态
            setIsInparam(updatedState);
        }
        // 使用对象展开语法创建新的a对象，并更新需要修改的键值对
        const updatedA = {
            ...isInParam.a,
            name: item.name,
            img: item.img // 更新name属性的值
        };
    
        // 创建新的状态对象，更新a对象
        const updatedState = {
            ...isInParam,
            a: updatedA
        };
    
        // 使用更新函数来更新状态
        setIsInparam(updatedState);
    }else{
        if(isInParam.a.name == item.name){
            inOldParm = isInParam.b
            // 使用对象展开语法创建新的a对象，并更新需要修改的键值对
            const updatedA = {
                ...isInParam.a,
                name: inOldParm.name,
                img: inOldParm.img // 更新name属性的值
            };
        
            // 创建新的状态对象，更新a对象
            const updatedState = {
                ...isInParam,
                a: updatedA
            };
        
            // 使用更新函数来更新状态
            setIsInparam(updatedState);
        }
         // 使用对象展开语法创建新的a对象，并更新需要修改的键值对
         const updatedB = {
            ...isInParam.b,
            name: item.name,
            img: item.img // 更新name属性的值
        };
    
        // 创建新的状态对象，更新a对象
        const updatedState = {
            ...isInParam,
            b: updatedB
        };
    
        // 使用更新函数来更新状态
        setIsInparam(updatedState);
    }
    setVisible5(false)
  }

  const goTrued=(isHas:boolean) => {
    setVisible5(true)
    setIsA(isHas)
  } 

  return <div className='homeBox'>
        <Mask visible={comminsoon} onMaskClick={() => setComminsoon(false)}>
        <div className='box-poup-ct-2'>
            <div className='open-comming'>
                <div className='to_tips'>
                coming soon
                </div>
                <div className='to_revice' onClick={() => setComminsoon(false)}>
                Return
                </div>
            </div>
        </div>
        </Mask>
        <Popup 
              visible={visible6}
              onMaskClick={() => {
                setVisible6(false)
              }}
              bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                minHeight: '40vh',
                background:'#202020',
                overflow:'scroll'
              }}
            >
                <div className='top-in-title'>
                    <div>Select a Token</div>
                    <img src={close} onClick={() => setVisible6(false)}  />
                </div>
                <div className='swap-lot'>
                    SWAPS & LIOUIDITY
                </div>
                <div className='default-lot'>
                    Default Transaction Speed(GWEl)
                </div>
                <div className='select-list'>
                    <div className={selecTed==1?'select':'no-select'}  onClick={() => setSelected(1)} >
                        Default
                    </div>
                    <div className={selecTed==2?'select':'no-select'}  onClick={() => setSelected(2)} >
                        Standard(3)
                    </div>
                    <div  className={selecTed==3?'select':'no-select'}  onClick={() => setSelected(3)}>
                        Fast(4)
                    </div>
                    <div  className={selecTed==4?'select':'no-select'} onClick={() => setSelected(4)}>
                        Instant(5)
                    </div>
                </div>
                <div className='default-lot'>
                Slippage Tolerance
                </div>
                <div className='select-list'>
                    <div className={selecTedA==1?'select':'no-select'} onClick={() => setSelectedA(1)}>
                        0.1%
                    </div>
                    <div className={selecTedA==2?'select':'no-select'} onClick={() => setSelectedA(2)}>
                        0.5%
                    </div>
                    <div  className={selecTedA==3?'select':'no-select'} onClick={() => setSelectedA(3)}>
                        1%
                    </div>
                    <input  className='in-put' placeholder='0.5' />
                </div>
                <div className='flex-space in-tx' >
                    <div className='in-line'>Tx deadline (mins)</div>
                    <input   className='in-put' placeholder='20' />
                </div>
        </Popup>
         <Popup 
              visible={visible5}
              onMaskClick={() => {
                setVisible5(false)
              }}
              bodyStyle={{
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                minHeight: '40vh',
                background:'#202020',
                overflow:'scroll'
              }}
            >
                <div className='top-in-title'>
                    <div>Select a Token</div>
                    <img src={close} onClick={() => setVisible5(false)}  />
                </div>
                <input className='top-input' placeholder='Search name or paste address' /> 
                <div className='top-in-title'>
                    <div>Common tokens</div>
                </div>
                <div className='u-flex'> 
                    <div className='u-flex box-bd-gary' onClick={()=>onSelect({name:'BTC',img:b1})}>
                        <img src={b1} />
                        <div>BTC</div>
                    </div>
                    <div className='u-flex box-bd-gary' onClick={()=>onSelect({name:'ETH',img:b2})}>
                        <img src={b2} />
                        <div>ETH</div>
                    </div>
                    <div className='u-flex box-bd-gary' onClick={()=>onSelect({name:'BNB',img:b3})}>
                        <img src={b3} />
                        <div>BNB</div>
                    </div>
                    <div className='u-flex box-bd-gary' onClick={()=>onSelect({name:'SOL',img:b4})}>
                        <img src={b4} />
                        <div>SOL</div>
                    </div>
                    <div className='u-flex box-bd-gary' onClick={()=>onSelect({name:'TRX',img:b5})}>
                        <img src={b5} />
                        <div>TRX</div>
                    </div>
                </div>
                <div>
                    <div className='coin-class' onClick={()=>onSelect({name:'BTC',img:b1})}>
                        <div className='u-flex'>
                            <img src={b1} />
                            <div>BTC</div>
                        </div>
                        <div>0.00</div>
                    </div>
                    <div className='coin-class' onClick={()=>onSelect({name:'ETH',img:b2})}>
                        <div className='u-flex'>
                            <img src={b2} />
                            <div>ETH</div>
                        </div>
                        <div>0.00</div>
                    </div>
                    <div className='coin-class' onClick={()=>onSelect({name:'BNB',img:b3})}>
                        <div className='u-flex'>
                            <img src={b3} />
                            <div>BNB</div>
                        </div>
                        <div>0.00</div>
                    </div>
                    <div className='coin-class' onClick={()=>onSelect({name:'SOL',img:b4})}>
                        <div className='u-flex'>
                            <img src={b4} />
                            <div>SOL</div>
                        </div>
                        <div>0.00</div>
                    </div>
                    <div className='coin-class' onClick={()=>onSelect({name:'TRX',img:b5})}>
                        <div className='u-flex'>
                            <img src={b5} />
                            <div>TRX</div>
                        </div>
                        <div>0.00</div>
                    </div>
                </div>
            </Popup>
    <Mask visible={visible} onMaskClick={() => setVisible(false)}>
        <div className='box-poup-ct-2'>
                <div className='swap-top-bg-4'>
                <div>
                    <div className='topDt-2'>
                        <div>
                            <div className='Token-fuc-1'>
                                <div>
                                    <div>lmport Pool</div>
                                    <div>Import an existing pool</div>
                                </div>
                                
                                <div>
                                    <img src={setting} ></img>
                                </div>
                            </div>
                        </div>
                    
                        
                        
                    </div>
                    <div>
                        <div className='u-flex ow-bg-color' onClick={()=>goTrued(true)}>
                            <div className='u-flex ow-igm '>
                                <img src={isInParam.a.img} />
                                <div>{isInParam.a.name}</div>
                            </div>
                            <img className='up-igm' src={updw} />
                        </div>
                    </div>
                    <div className='plus'>+</div>
                    <div>
                        <div className='u-flex ow-bg-color' onClick={()=>goTrued(false)}>
                            <div className='u-flex ow-igm '>
                                <img src={isInParam.b.img} />
                                <div>{isInParam.b.name}</div>
                            </div>
                            <img className='up-igm' src={updw} />
                        </div>
                    </div>
                    <div className='flex-center' style={{marginTop:'.3rem'}}>
                        <div className='draw-img-ct' onClick={()=> setComminsoon(true)} >
                            Add liquidity
                        </div>
                    </div>
                    
                </div>
            
            </div>
            <img src={close} className='close-ig' onClick={() => setVisible(false)} />


        </div>
        
    </Mask>
    <div className='flex-space' style={{padding:'0 10px',marginBottom:'20px'}}>
        <div className={isSelected==1?'select-tb':'no-select-tb'} onClick={() => setIsSelected(1)}>
        swap
        </div>
        <div className={isSelected==2?'select-tb':'no-select-tb'} onClick={() => setIsSelected(2)}>
        Liquidity
        </div>
    </div>
    {
        isSelected == 1 &&
        <div className='swap-top-bg-1'>
        <div>
            <div className='topDt-2'>
                <div>
                    <div className='toName'>
                        <span className='span-a'>SOAVE</span>
                        <span className='span-b'>Swap</span>
                    </div>
                    <div className='Token-fuc'>
                        <div>Trade tokens in an instant</div>
                        <div>
                            <img src={setting} onClick={() => setVisible6(true)}></img>
                            <img src={history} style={{margin:'0 10px'}} ></img>
                            <img src={redo} ></img>
                        </div>
                    </div>
                </div>
               
                
                 
            </div>
            <div className='bg-name-info-2'></div>
            <div>
                <div className='u-flex' onClick={() => goTrued(true)}>
                    <div className='u-flex ow-igm'>
                        <img src={isInParam.a.img} />
                        <div>{isInParam.a.name}</div>
                    </div>
                    <img className='up-igm' src={updw}  />
                    
                </div>
                <input className='set-input' placeholder='0.00' />
            </div>
             <img className='ig-name' src={arrowup} />
             
            <div>
                <div className='u-flex' onClick={() => goTrued(false)} >
                    <div className='u-flex ow-igm'>
                        <img src={isInParam.b.img} />
                        <div>{isInParam.b.name}</div>
                    </div>
                    <img className='up-igm' src={updw} />
                </div>
                <input className='set-input' placeholder='0.00' />
            </div>
            <div className='data-setting-info'>
                <div>Slippage Tolerance</div>
                <div>0.5%</div>
            </div>
            <div className='flex-center'>
                <div className='draw-img-ct'  onClick={()=> setComminsoon(true)} >
                    Swap
                </div>
            </div>
            
        </div>
        <div className='file-bar'>
        <div>Pair</div>
        <div>Price($)</div>
        <div>24h%</div>
    </div>
    <div className='file-bar-1'>
        <div>
            <img src={b1} />
            <div>
                BTC/USDT
            </div>
        </div>
        <div>$63,865.67</div>
        <div>
            <div>- 2.51%</div>
        </div>
    </div>
    <div className='file-bar-1'>
        <div>
            <img src={b2} />
            <div>
                ETH/USDT
            </div>
        </div>
        <div>$33,47.17</div>
        <div>
            <div>- 2.84%</div>
            </div>    
    </div>
    <div className='file-bar-1'>
        <div>
            <img src={b3} />
            <div>
                BNB/USDT
            </div>
        </div>
        <div>$584.40</div>
        <div>
            <div>- 0.81%</div>
          </div>     
    </div>
    <div className='file-bar-1'>
        <div>
            <img src={b4} />
            <div>
                SOL/USDT
            </div>
        </div>
        <div>$173.29</div>
        <div>
            <div>- 3.55%</div>
        </div>
    </div>
    <div className='file-bar-1'>
        <div>
            <img src={b5} />
            <div>
                TRX/USDT
            </div>
        </div>
        <div>$0.117</div>
        <div>
            <div>- 3.13%</div>
        </div>
    </div>
    </div>
    }
    {
        isSelected == 2 &&
        <div className='swap-top-bg-3'>
             <div>
            <div className='topDt-2'>
                <div>
                    <div className='toName'>
                        <span className='span-a'>SOAVE</span>
                        <span className='span-b'>Swap</span>
                    </div>
                    <div className='Token-fuc'>
                        <div>Trade tokens in an instant</div>
                        <div>
                            <img src={setting} style={{marginRight:'10px'}}></img>
                            <img src={history} ></img>
                        </div>
                    </div>
                </div>
               
                
                 
            </div>
            <div className='bg-name-info-2'></div>
           
            <div className='dotips-cs'>

                <div className='tips-game'>
                    <div>No liquidity found </div>
                    <div>Don't see a pair you joined ?</div>
                </div>  
                <div className='finde-in'>
                    FIND other Lp tokens
                </div>      
            </div>    
            <div className='flex-center'>
                <div className='draw-img-ct'  onClick={()=>setVisible(true)}>
                + Add liquidity
                </div>
            </div>
            
        </div>
        </div>    
    }
    
    
  </div>
}

export default SwapView; 