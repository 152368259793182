import { useEffect, useState } from 'react';

import '../../assets/css/index.less'
import mk_1 from '../../assets/img/mk_1.png';
import a1 from '../../assets/img/a1.png';
import a2 from '../../assets/img/a2.png';
import a3 from '../../assets/img/a3.png';
import a4 from '../../assets/img/a4.png';
import a5 from '../../assets/img/a5.png';
import a6 from '../../assets/img/a6.png';
import a7 from '../../assets/img/a7.png';
import a8 from '../../assets/img/a8.png';

import x1 from '../../assets/img/x1.png';
import x2 from '../../assets/img/x2.png';
import x3 from '../../assets/img/x3.png';

import l4 from '../../assets/img/l4.png';
import l5 from '../../assets/img/l5.png';
import l6 from '../../assets/img/l6.png';
import l7 from '../../assets/img/l7.png';
import l8 from '../../assets/img/l8.png';
import l9 from '../../assets/img/l9.png';
import close from '../../assets/img/close.png';
import ranka from '../../assets/img/rank_a.png';
import rankb from '../../assets/img/rank-b.png';
import { Link } from 'react-router-dom';
import { Button, Mask, Space,Badge, TabBar } from 'antd-mobile';
import 'antd/dist/reset.css';
import {
    AppOutline,
    MessageOutline,
    MessageFill,
    UnorderedListOutline,
    UserOutline,
  } from 'antd-mobile-icons'
const HomeView = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
  }, [])


  const [activeKey, setActiveKey] = useState('todo')

  return <div className='homeBox'>
   <Mask visible={visible} onMaskClick={() => setVisible(false)}>
        <div className='box-poup-ct'>
            <div className='box-poup'>
                <div className='title-label'>RANKING</div>
                <div className='to-ct-rk'>
                    <div className='to-ct-rk-1 to-ct-l-3'>
                        <div className='top-ovr'>
                            <img src={x1} />
                            <div className='name-in'>Andy</div>
                        </div>
                        <div className='pos-tob'>Top2</div>
                    </div>
                    <div className='to-ct-rk-1 to-ct-l-2'>
                        <div className='top-ovr'>
                            <img src={x1} />
                            <div className='name-in'>Tony</div>
                        </div>
                        <div className='pos-tob'>Top1</div>
                    </div>
                    <div className='to-ct-rk-1 to-ct-l-1'>
                        <div className='top-ovr'>
                            <img src={x1} />
                            <div className='name-in'>David</div>
                        </div>
                        <div className='pos-tob'>Top3</div>
                    </div>
                </div>
                <div className='label-list'>
                    <div className='label-list-table'>
                        <img src={l4} />
                        <div>Ross</div>
                    </div>
                    <div className='label-list-table'>
                        <img src={l5} />
                        <div>Aaron</div>
                    </div>
                    <div className='label-list-table'>
                        <img src={l6} />
                        <div>Jeff</div>
                    </div>
                    <div className='label-list-table'>
                        <img src={l7} />
                        <div>Jeff</div>
                    </div>
                    <div className='label-list-table'>
                        <img src={l8} />
                        <div>Jeff</div>
                    </div>
                    <div className='label-list-table'>
                        <img src={l9} />
                        <div>Jeff</div>
                    </div>
                </div>
                 <img src={close} className='close-ig' onClick={() => setVisible(false)} />

            </div>

        </div>
        
    </Mask>
    <div className='bg-border'>
        <div>
            <div className='topDt'>
                <div>
                    <div className='toName'>
                        <span className='span-a'>ABOUT</span>
                        <span className='span-b'>Soave</span>
                    </div>
                    <div className='toContent'>
                        <div>
                            Soave builds a social aggregation DEFI protocol that maximizes user profits based on the Binance ecosystem architecture.
                        </div>
                        <div>
                            The vision of the agreement is to help more friends obtain stable DEFI agreements and gain profits
                        </div>
                    </div>
                </div>
                
                
            </div>
            <div>

            </div>
                <div className='join-info'  onClick={() => setVisible(true)}>
                    <div>JOIN IN COMMUNIRTY</div>
                    <img src={rankb} />
                </div>
                <div className='tipsTitle'>
                    Changing the traditional blockchain  investment concept and int
                </div>
                <div className='bg-border-2'>
                    <div>
                        <div className='header-top-title'>RANKING</div>
                        <div className='bg-name-info'> 
                            <div>
                                <div className='span-1'>Important members</div>
                                <div className='span-2'>Soave</div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='bg-name-info-1'> 
                    <div>
                        <div className='span-1'>
                            <img  src={mk_1}  />
                            <div>0X...6789</div>
                        </div>    
                        <div className='span-2'>10000</div>
                    </div>
                    <div>
                        <div className='span-1'>
                            <img  src={mk_1}  />
                            <div>0X...6789</div>
                        </div>    
                        <div className='span-2'>10000</div>
                    </div>
                    <div>
                        <div className='span-1'>
                            <img  src={mk_1}  />
                            <div>0X...6789</div>
                        </div>    
                        <div className='span-2'>10000</div>
                    </div>
                </div>
            </div> 
            <div className='bg-border-3'>
                    <div>
                        <div className='header-top-title'>RANKING</div>
                        <div className='bg-name-info-2'> 
                            <div>
                                <div className='span-1'>Important members</div>
                                <div className='span-2'>Soave</div>
                                <div className='span-3'>Soave</div>
                                <div className='span-4'>Soave</div>
                            </div>
                            
                        </div>
                    </div>
                    <div className='bg-name-info-3'> 
                        <div>
                            <div className='span-1'>
                                <img  src={mk_1}  />
                                <div>0X...6789</div>
                            </div>    
                            <div className='span-2'>10000</div>
                            <div className='span-3'>10000</div>
                            <div className='span-4'>10000</div>
                        </div>
                        <div>
                            <div className='span-1'>
                                <img  src={mk_1}  />
                                <div>0X...6789</div>
                            </div>    
                            <div className='span-2'>10000</div>
                            <div className='span-3'>10000</div>
                            <div className='span-4'>10000</div>
                        </div>
                        <div>
                            <div className='span-1'>
                                <img  src={mk_1}  />
                                <div>0X...6789</div>
                            </div>    
                            <div className='span-2'>10000</div>
                            <div className='span-3'>10000</div>
                            <div className='span-4'>10000</div>
                        </div>
                    </div> 
                    <div className='new-mb'>
                        <span>
                            New meber
                        </span>
                    </div> 
                    <div className='top-gap-imgs'>
                        <img src={a1} />
                        <img src={a2} />
                        <img src={a3} />
                        <img src={a4} />
                        <img src={a5} />
                        <img src={a6} />
                        <img src={a7} />
                        <img src={a8} />
                    </div>
                    <div className='new-mb-2'>
                        <span>
                        Join  NOW
                        </span>
                    </div> 
               </div>   
        </div>
    </div>
  </div>
}

export default HomeView;