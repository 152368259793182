import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import menuImg from '../assets/img/menu.png';

import '../assets/css/head.less'
import { useWeb3Modal } from '@web3modal/ethers/react'
import { onAdd } from '../util/api';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const Hedaer = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { open } = useWeb3Modal()
  const { address} = useWeb3ModalAccount()
  const [openPH, setOpenPH] = useState(false);

  const menuArr = [
    {to:'/',name:'home'},
    {to:'/home1',name:'home1'},
    {to:'/home2',name:'home2'}
  ]


  useEffect(()=>{
    if(address){
      let addr = localStorage.getItem('address') || null
      if(addr){
        if(addr !== address){
          localStorage.setItem('address',address)
          navigate(0)
        }
      }else{
        localStorage.setItem('address',address)
      }
    }
  },[address])

  const showDrawer = () => {
    setOpenPH(true);
  };

  const onClose = () => {
    setOpenPH(false);
  };

  const isups = (name:string) => { 
    return (location.pathname === name)
  }


  return <div className='header_box'>
    <ScrollToTop></ScrollToTop>
    <div className='flex_sb'>
      <div>
        <Link to="/" className='wallte-logo' >
          <img src={logo} alt="logo" />
          {/* <div>soave</div> */}
        </Link>
        <div className='web menu_con'>
          <ul>
            {
              menuArr.map((item,idx)=><li key={item.name}>
                <NavLink className={({ isActive }) => { return (isActive || isups(item.to)) ? "ups" : ""; }} to={item.to}>{item.name}</NavLink>
              </li>)
            }
          </ul>
        </div>
      </div>

      <div>
        <div className='wallet_box'>
          <button onClick={() => open()} className={address && 'ava_btn'}>
            {address ? <div className='avater'><i></i>{onAdd(address)}</div> : 'Connect'}
          </button>
        </div>
        {/* <div className='phone_menu phone'>
          <img src={menuImg} alt="" onClick={showDrawer} />
        </div> */}
      </div>

      <Drawer placement="right" open={openPH} closable={false} onClose={onClose} width={360}>
        <div className='menu_phone_box'>
          <div className='menu_close' onClick={onClose}>
            ×
          </div>
          <div className='menu_con'>
          <ul onClick={onClose}>
            {
              menuArr.map((item,idx)=><li key={item.name}>
                <NavLink className={({ isActive }) => { return (isActive || isups(item.to)) ? "ups" : ""; }} to={item.to}>{item.name}</NavLink>
              </li>)
            }
          </ul>
          </div>
        </div>
      </Drawer>
    </div>

  </div>
}

const ScrollToTop = (props: any) => {

  const { pathname } = useLocation();

  useEffect(() => {

    window.scrollTo(0, 0)

  }, [pathname]);

  return props.children;

}

export default Hedaer;

