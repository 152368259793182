import { useEffect, useState } from 'react';

import '../../assets/css/about.less'
import dw_ig from '../../assets/img/dw_ig.png';
import keyBorder from '../../assets/img/keyBorder.png';
import s1 from '../../assets/img/s1.png';
import s2 from '../../assets/img/s2.png';
import s3 from '../../assets/img/s3.png';
import s4 from '../../assets/img/s4.png';
import cs1 from '../../assets/img/cs_1.png';
import cs2 from '../../assets/img/cs_2.png';
import cs3 from '../../assets/img/cs_3.png';

import f1 from '../../assets/img/f1.png';
import f2 from '../../assets/img/f2.png';
import f3 from '../../assets/img/f3.png';
import f4 from '../../assets/img/f4.png';

const About = () => {


  useEffect(() => {
  }, [])



  return <div className='homeBox'>
    <div className='draw-rec-title'>
        <div>About  Soave</div>
    </div>
    <div className='bg-border-about'>
      <div>
        <div>Soave is a social network based on the Defi blockchain protocol, whose core function is to list your friends as target friends, making it easier for users to interact with their friends and find people and institutions that match their business value.</div>

        <div>Based on the Binance ecosystem architecture, Soave is committed to building a community aggregation DEFI protocol that maximizes user profits, thereby achieving a win-win situation for users, friends, commercial institutions, and other parties.</div>
      </div>
      <img src={keyBorder} />
    </div>
    <div className='draw-img-about'>
      Start exploring
    </div>
    <div className='border-title-about'>
      <div>Core functions</div>
      <div></div>
      <div>and features</div>
    </div>
    <div className='shiled_bx_about'>
      <div className='box-about'>
        <img src={s1} />
      </div>
      <div>
        <div>Social interaction</div>
        <div>Users can interact with their friends on Soave, including chatting, sharing information, participating in common activities, etc.</div>
      </div>
    </div>
    <div className='shiled_bx_about'>
      <div className='box-about'>
        <img src={s2} />
      </div>
      <div>
        <div>Business value matching</div>
        <div>Soave will use algorithms and data mining to help users find people and institutions that match their business value, thereby improving their business opportunities and profits.</div>
      </div>
    </div>
    <div className='shiled_bx_about'>
      <div className='box-about'>
      <img src={s3} />
      </div>
      <div>
        <div>Reward mechanism</div>
        <div>Soave will provide a reward mechanism to encourage users to actively participate in social interaction, share information, participate in business cooperation, etc., in order to receive corresponding rewards.</div>
      </div>
    </div>
    <div className='shiled_bx_about'>
      <div className='box-about'>
      <img src={s4} />
      </div>
      <div>
        <div>Ecological Aggregation DEFI Protocol</div>
        <div>Soave will build a community aggregation DEFI protocol based on the Binance ecological architecture, which will integrate multiple Defi applications and protocols to provide users with richer and more convenient Defi services.</div>
      </div>
    </div>
    <div className='draw-rec-title-2'>
        <div>Team</div>
    </div>
    <div className='drwt-ic'>
      <div>
        <img src={cs1} />
        <div className='c-1'>Zack</div>
        <div className='c-2'>coo</div>
      </div>
      <div>
        <img src={cs2} />
        <div className='c-1'>Bruni</div>
        <div className='c-2'>ceo</div>
      </div>
      <div>
        <img src={cs3} />
        <div className='c-1'>Bibo</div>
        <div className='c-2'>cto</div>
      </div>
    </div>
    <div className='road-map'>road map</div>
    <div className='tree-date'>
      <div className='card-box'>
      Link 200000 users as network cold start users
      </div>
      <div className='card-box'>
      Establishing a user relationship network
      </div>
      <div className='card-box'>
      Link SOL users to Soave
      </div>
      <div className='tree-line'></div>
    </div>
    <div className='bottom-cls'>
      <img src={f1} />
      <img src={f2} />
      <img src={f3} />
      <img src={f4} />
    </div>
  </div>
}

export default About; 