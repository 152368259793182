import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/img/logof.png';
import seerImg from '../assets/img/seer.svg';
import k1 from '../assets/img/k1.png';
import k2 from '../assets/img/k2.png';
import k3 from '../assets/img/k3.png';
import home1 from '../assets/img/home.png';
import '../assets/css/nav.less'
import { Badge, TabBar } from 'antd-mobile'

import {
  AppOutline,
  MessageOutline,
  MessageFill, 
  UnorderedListOutline,
  UserOutline,
} from 'antd-mobile-icons'
import { useEffect,useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import {useNavigate} from 'react-router-dom'

const Footer = () => {
  const [activeKey, setActiveKey] = useState('home')
  const { address } = useWeb3ModalAccount()
  const { walletProvider }:any = useWeb3ModalProvider()
  const navigate = useNavigate()

  useEffect(()=>{
    if(address){
      // getFactoryContract()
    }
  },[address])

  const tabs = [
    {
      key: 'home',
      title: 'Home',
      icon: <AppOutline />,
    },
    {
      key: 'swap',
      title: 'Swap',
      icon: <UnorderedListOutline />,

    },
    {
      key: 'airdrop',
      title: 'Airdrop',
      icon: (active: boolean) =>
        active ? <MessageFill /> : <MessageOutline />,

    },
    {
      key: 'token',
      title: 'Token',
      icon: <UserOutline />,

    },
    { 
      key: 'about',
      title: 'About',
      icon: <UserOutline />,

    },
  ]

  const inKey = (item:any) => {
    console.log(item)
    if(item=='home'){
      navigate('/')
      setActiveKey('home')
    }
    if(item=='swap'){
      navigate('/swap')
      setActiveKey('swap')

    }
    if(item=='airdrop'){
      navigate('/airdrop')
      setActiveKey('airdrop')

    }
    if(item=='token'){
      navigate('/wallte')
      setActiveKey('token')

    }
    if(item=='about'){
      navigate('/about')
      setActiveKey('about')

    }
  }
  

  return <div className='footer_box'>
    <div className='content'>
    
      <ul className='flext-space k-lfg'>
        <li>
          <img src={k1} />
        </li>
        <li>
          <img src={k2} />

        </li>
        <li>
          <img src={k3} />

        </li>
      </ul>
    </div>

      <div className='footer_div-1'>
        <span>Copyright© 2024 | Soave</span>
        {/* <div className='head_icon'>
          <a href="https://app.seer.eco/" target={'_blank'} ><img src={xImg} alt="logo" /></a>
          <a href="https://app.seer.eco/" target={'_blank'} ><img src={bookImg} alt="logo" /></a>
          <a href="https://app.seer.eco/" target={'_blank'} ><img src={telImg} alt="logo" /></a>
        </div> */}
      </div>
      <div className='to-fxied'>
      <TabBar activeKey={activeKey} onChange={inKey} style={{background:'#202020'}}>
          {tabs.map(item => (
            <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
          ))}
        </TabBar>
      </div>
      
  </div>
}

export default Footer;