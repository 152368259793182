// import React from 'react';
import { createHashRouter } from "react-router-dom";
import App from './App';
// import Home from './pages/Home';
import Home1 from "./pages/Home1";
import Home2 from "./pages/Home2";
import Index from "./pages/Home/Index";
import Swap from "./pages/Swap/Index";
import Airdrop from "./pages/Airdrop/Index";
import Wallte from "./pages/Wallte/Index";
import About from "./pages/About/Index";

const router = createHashRouter([
    {
        path: '/',
        element: <App />,
        children:[
            {
                path: '/',
                element: <Index />,
            },
            {
                path: '/Swap',
                element: <Swap />,
            },
            {
                path: '/Airdrop',
                element: <Airdrop />,
            },
            {
                path: '/Wallte',
                element: <Wallte />
            },
            {
                path: '/About',
                element: <About />
            }
        ]
    },
    
]);

export default router;
