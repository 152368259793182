import { useEffect, useState } from 'react';

import '../../assets/css/walletLes.less'
import dw_ig from '../../assets/img/dw_ig.png';
import b1 from '../../assets/img/b1.png';
import b2 from '../../assets/img/b2.png';
import b3 from '../../assets/img/b3.png';
import b4 from '../../assets/img/b4.png';
import b5 from '../../assets/img/b5.png';
import b6 from '../../assets/img/b6.png';
import { Button, Mask, Space,Popup } from 'antd-mobile';
import 'antd/dist/reset.css';

const Wallet = () => {
  const [comminsoon,setComminsoon] = useState(false)

  useEffect(() => {
  }, [])



  return <div className='homeBox'>
    <Mask visible={comminsoon} onMaskClick={() => setComminsoon(false)}>
        <div className='box-poup-ct-2'>
            <div className='open-comming'>
                <div className='to_tips'>
                coming soon
                </div>
                <div className='to_revice' onClick={() => setComminsoon(false)}>
                Return
                </div>
            </div>
        </div>
        </Mask>
   <div className='bg-wt-bg'> 
    <div>
      <div className='tp-tile'>Wallet</div>
      <div className='tips-tp'>Total balance</div>
      <div className='flex-space'>
        <div className="top-garea">
          <img src={b1} />
          <div>0 BNB</div>
        </div>
        <div className='line-gup'></div>
        <div className="top-garea">
          <img src={b6} />
          <div>0 SAV</div>
        </div>
      </div>
      <div className='in-pay' onClick={()=> setComminsoon(true)}> 
        Pay
      </div>
    </div>
   </div>
   <div className='bg-wt-bg'> 
    <div>
      <div className='flex-space'>
        <div className="top-garea">
          <img src={b4} />
          <div>0 SOL</div>
        </div>
        <div className='line-gup'></div>
        <div className="top-garea">
          <img src={b6} />
          <div>0 SAV</div>
        </div>
      </div>
      <div className='in-pay' onClick={()=> setComminsoon(true)}>
        Pay
      </div>
    </div>
    
   </div>
   <div className='prop-rank-list'>
    <div className='profit-line'>
      Portfolio
      <div></div>
    </div>
    <div className='file-bar-2'>
          <div>
              <img src={b1} />
              <div>
                  BTC/USDT
              </div>
          </div>
          <div>$63,865.67</div>
          <div>- 2.51%</div>
      </div>
      <div className='file-bar-2'>
          <div>
              <img src={b2} />
              <div>
                  ETH/USDT
              </div>
          </div>
          <div>$33,47.17</div>
          <div>- 2.84%</div>
      </div>
      <div className='file-bar-2'>
          <div>
              <img src={b3} />
              <div>
                  BNB/USDT
              </div>
          </div>
          <div>$584.40</div>
          <div>- 0.81%</div>
      </div>
      <div className='file-bar-2'>
          <div>
              <img src={b4} />
              <div>
                  SOL/USDT
              </div>
          </div>
          <div>$173.29</div>
          <div>- 3.55%</div>
      </div>
      <div className='file-bar-2'>
          <div>
              <img src={b5} />
              <div>
                  TRX/USDT
              </div>
          </div>
          <div>$0.117</div>
          <div>- 3.13%</div>
      </div>
   </div>

   <div className='profit-line pric'>
      Portfolio
    </div>
    <div className='bg-wt-bg bg-wt-bg-1'> 
    <div>
      <div className='tp-tile'>AWARD</div>
      <div className='flex-space'>
        <div className="top-garea">
          <img src={b1} />
          <div>0 BNB</div>
        </div>
        <div className='line-gup'></div>
        <div className="top-garea">
          <img src={b6} />
          <div>0 SAV</div>
        </div>
      </div>
    </div>
    
   </div>
   <button className='in-button' onClick={()=> setComminsoon(true)} > Share </button>
    <div className='bg-wt-bg bg-wt-bg-1'> 
    <div>
      <div className='flex-space'>
        <div className="top-garea">
          <img src={b4} />
          <div>0 SOL</div>
        </div>
        <div className='line-gup'></div>
        <div className="top-garea">
          <img src={b6} />
          <div>0 SAV</div>
        </div>
      </div>
    </div>
    
   </div>
   <button className='in-button' onClick={()=> setComminsoon(true)} > Share </button>  
  </div>
}

export default Wallet; 