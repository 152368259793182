import { useEffect, useState } from 'react';

import '../../assets/css/airdrop.less'
import { Button, Mask, Space,Popup } from 'antd-mobile';
import 'antd/dist/reset.css';
import dw_ig from '../../assets/img/dw_ig.png';

const Airdrop = () => {
    const [comminsoon,setComminsoon] = useState(false)

  useEffect(() => {
  }, [])



  return <div className='homeBox'>
    <Mask visible={comminsoon} onMaskClick={() => setComminsoon(false)}>
        <div className='box-poup-ct-2'>
            <div className='open-comming'>
                <div className='to_tips'>
                coming soon
                </div>
                <div className='to_revice' onClick={() => setComminsoon(false)}>
                Return
                </div>
            </div>
        </div>
        </Mask>
    <div className='bg-tips'> 
            <div>Join in sosat ecology: </div>
            <div>Share profits up to 1.000.000 X!</div>
    </div>
    <div className='bg-rop-bg'>
        <img className='dw-ig' src={dw_ig} />
        <div>
            <div className='top-title'>Airdrop Records</div>
            <div className='tp-flex'>
                <div>ConnectWallet</div>
                <div onClick={()=> setComminsoon(true)}>Reward 150 SAV</div>
            </div>
            <div className='tp-flex-1'>
                <div>ConnectTwitter</div>
                <div onClick={()=> setComminsoon(true)}>Connect</div>
            </div>
            <div className='tp-flex-1'>
                <div>Send Twitter</div>
                <div onClick={()=> setComminsoon(true)}>Send</div>
            </div>
        </div>
    </div>
    <div className='flex-space bg-r-2' >
        <div className='bg-rop-bg-2'>
            <div>Airdrops Available</div>
            <div>150 SAV</div>
        </div>
        <div className='bg-rop-bg-2'>
            <div>Airdrops Received</div>
            <div>0 SAV</div>
        </div>
    </div>
    <div className='flex-center'>
        <div className='draw-rec-ct' onClick={()=> setComminsoon(true)}>
        Click To Receive
        </div>
    </div>
  </div>
}

export default Airdrop; 