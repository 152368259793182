import React, { useEffect } from 'react';
import Hedaer from './component/Hedaer';
import { Outlet } from 'react-router-dom';
import { StoreContextProvider } from './store/ContextProvider';
import { createWeb3Modal,   defaultConfig } from '@web3modal/ethers/react'
import { Toaster } from 'react-hot-toast';
import Footer from './component/Footer';
import { chaninArr } from './util/config';

const projectId = '7044371c33b95916bd1dd995766b6380'

const metadata = {
  name: 'My Website',
  description: 'My Website description',
  url: 'https://mywebsite.com',
  icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  defaultChain:chaninArr[2],
  chains: chaninArr,
  projectId,
  includeWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
    '20459438007b75f4f4acb98bf29aa3b800550309646d375da5fd4aac6c2a2c66',
    '38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662',
  ]
})

function App() {
  return (
      <StoreContextProvider>
        <Toaster toastOptions={{duration:1500}} />
        <Hedaer />
        <div className='padding_con'>
          <Outlet />
        </div>
        <Footer />
      </StoreContextProvider>
  );
}

export default App;
